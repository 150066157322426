body {
    margin: 0;
    line-height: normal;
  }
  :root {
    /* Colors */
    --color-surface-default: #fff;
  }

.div {
    width: 100%;
    position: relative;
    background-color: var(--color-surface-default);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 279px 0 0;
    box-sizing: border-box;
    min-height: 800px;
    letter-spacing: normal;
  }