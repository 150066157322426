@font-face {
    font-family: "Pretendard JP";
    src: url("/public/font/PretendardJP-Regular.otf");
    font-weight: 400;
}

@font-face {
    font-family: "Pretendard JP";
    src: url("/public/font/PretendardJP-SemiBold.otf");
    font-weight: 600;
}

body {
    margin: 0;
    line-height: normal;
}

:root {
    /* fonts */
    --v2-ui-1-strong: "Pretendard JP";

    /* font sizes */
    --v2-ui-1-strong-size: 17px;
    --v2-paragraph-2-size: 15px;
    --v2-headline-6-size: 19px;

    /* Colors */
    --color-surface-overlay: #fff;
    --color-gray-100: #7c7f85;
    --color-background-neutral-boldest: #1f2125;
    --color-scrim-default: rgba(7, 20, 55, 0.31);
    --color-background-transparent: rgba(255, 255, 255, 0);
    --color-text-subtle: #3e414a;
    --text-primary: #32373e;
    --color-text-default: #2a2c32;
    --color-background-neutral-default: rgba(30, 43, 68, 0.08);
    --color-text-subtlest: #707684;
    --color-background-accent-red-bolder: #fa5b8c;

    /* Spacing */
    --size-size016: 16px;
    --size-size024: 24px;
    --corner-radius-cornerradius024: 24px;
    --size-size008: 8px;
    --size-size020: 20px;
    --size-size056: 56px;
    --corner-radius-cornerradius016: 16px;
    --size-size002: 2px;
    --corner-radius-cornerradius0161: 16px;
    --size-size0161: 16px;
    --size-size0081: 8px;
    --size-size0201: 20px;
    --size-size0561: 56px;
    --size-size0021: 2px;

    /* Gaps */
    --gap-base: 16px;
    --gap-5xs: 8px;

    /* Paddings */
    --padding-5xl: 24px;
    --padding-base: 16px;
    --padding-xl: 20px;
    --padding-11xs: 2px;

    /* Border radiuses */
    --br-5xl: 24px;
    --br-base: 16px;
}

.text-titleDialog {
    align-self: stretch;
    position: relative;
    line-height: 28px;
    font-weight: 600;
}

.contents-paragraphDialog {
    flex: 1;
    position: relative;
    line-height: 22px;
}

.dialog-partsparagraph-textDialog {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: var(--v2-paragraph-2-size);
    color: var(--color-text-subtlest);
}

.container-text-groupDialog {
    align-self: stretch;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--size-size016);
    gap: var(--size-size008);
}

.text5Dialog {
    flex: 1;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 121%;
    flex-shrink: 0;
}

.container-help-text1Dialog {
    align-self: stretch;
    overflow: hidden;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--size-size002) 0;
}

.text-label5Dialog {
    height: 24px;
    flex: 1;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.button-button-41Dialog,
.container5Dialog {
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.container5Dialog {
    height: var(--size-size056);
    flex: 1;
    border-radius: var(--corner-radius-cornerradius016);
    background-color: var(--color-background-neutral-default);
    display: flex;
    padding: 0 var(--size-size020);
    box-sizing: border-box;
    max-width: 121%;
    flex-shrink: 0;
}

.button-button-41Dialog {
    align-self: stretch;
    overflow: hidden;
    display: none;
}

.text-label6Dialog {
    height: 24px;
    flex: 1;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.button-button-31Dialog,
.container6Dialog {
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.container6Dialog {
    height: var(--size-size056);
    flex: 1;
    border-radius: var(--corner-radius-cornerradius016);
    background-color: var(--color-background-neutral-default);
    display: flex;
    padding: 0 var(--size-size020);
    box-sizing: border-box;
    max-width: 121%;
    flex-shrink: 0;
}

.button-button-31Dialog {
    align-self: stretch;
    overflow: hidden;
    display: none;
}

.text-label7Dialog {
    flex: 1;
    position: relative;
    font-size: var(--v2-ui-1-strong-size);
    line-height: 24px;
    font-weight: 600;
    font-family: var(--v2-ui-1-strong);
    color: var(--color-surface-overlay);
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.button-button-21Dialog,
.container7Dialog {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.container7Dialog {
    flex: 1;
    border-radius: var(--corner-radius-cornerradius016);
    background-color: var(--color-background-neutral-boldest);
    padding: var(--padding-base) var(--size-size020);
    white-space: nowrap;
}

.button-button-21Dialog {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    align-self: stretch;
    overflow: hidden;
}

.text-label8Dialog {
    flex: 1;
    position: relative;
    font-size: var(--v2-ui-1-strong-size);
    line-height: 24px;
    font-weight: 600;
    font-family: var(--v2-ui-1-strong);
    color: var(--color-text-subtle);
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.button-button-11Dialog,
.container8Dialog {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.container8Dialog {
    flex: 1;
    border-radius: var(--corner-radius-cornerradius016);
    background-color: var(--color-background-transparent);
    padding: var(--padding-base) var(--size-size020);
}

.button-button-11Dialog {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    align-self: stretch;
    overflow: hidden;
}

.button-dock1Dialog,
.container-button-group1Dialog,
.dialogDialog {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.container-button-group1Dialog {
    align-self: stretch;
    align-items: flex-start;
    gap: var(--size-size008);
    color: var(--color-text-subtle);
}

.button-dock1Dialog,
.dialogDialog {
    overflow: hidden;
    gap: var(--size-size016);
}

.button-dock1Dialog {
    align-self: stretch;
    align-items: center;
    padding: 0 var(--size-size016);
    text-align: center;
    font-size: var(--v2-ui-1-strong-size);
    color: var(--color-text-subtlest);
}

.dialogDialog {
    position: absolute;
    border-radius: var(--corner-radius-cornerradius024);
    background-color: var(--color-surface-overlay);
    width: 302px;
    align-items: flex-start;
    padding: var(--size-size024) 0 var(--size-size016);
    box-sizing: border-box;
    min-width: 270px;
    z-index: 2;
    text-align: left;
    font-size: var(--v2-headline-6-size);
    color: var(--color-text-default);
    font-family: var(--v2-ui-1-strong);
}

.scrim,
.section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.scrim {
    background-color: var(--color-scrim-default);
    overflow: hidden;
    z-index: 1;
    display : flex;
    justify-content: center;
    align-items : center;
}