.loading_circle {
    animation: 1s linear 1ms infinite circle_loading;
    stroke-dasharray: 100,76;
    stroke-dashoffset: 44;
    stroke-linecap: round;
}

@keyframes circle_loading {
    0% {
        stroke-dashoffset: 44;
    }

    100% {
        stroke-dashoffset: 220;
    }
}